import { Routes, Route } from 'react-router-dom';
import SignupPage from './pages/SignupPage';
import { Toaster } from 'react-hot-toast';
import PageNotFound from './pages/PageNotFound';
import RedirectToAppStoreAndPlayStore from './components/RedirectToAppStoreAndPlayStore';
import LeadNotFoundPage from './components/MainScreen/LeadNotFoundPage';
// import { useEffect } from "react";

function App() {
  // useEffect(() => {
  //   const handleResize = () => {
  //     const viewportHeight = window.innerHeight;
  //     const viewportWidth = window.innerWidth;
  //     const scalingRatio = window.devicePixelRatio;

  //     // Check if conditions are met
  //     if (scalingRatio < 2 && viewportHeight < 800 && viewportWidth > 1450) {
  //       // Calculate the desired zoom level
  //       const desiredZoom = (1450 / viewportWidth) * 100; // Calculate the percentage zoom
  //       console.log("desiredZoom :", desiredZoom);
  //       // Set the zoom level using CSS
  //       // document.body.style.zoom = `${desiredZoom}%`;
  //       document.body.style.zoom = `${100}%`;
  //     } else {
  //       // Reset the zoom level to 100% if conditions are not met
  //       document.body.style.zoom = "100%";
  //     }
  //   };

  //   // Attach the resize event listener
  //   window.addEventListener("resize", handleResize);

  //   // Call the initial check
  //   handleResize();

  //   // Clean up the event listener
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  return (
    <>
      <Toaster toastOptions={{ duration: 6000 }} />
      {/* <div className=" flex item-center justify-center h-screen bg-neutral text-center"> */}
      {/* <div className="m-auto bg-white w-full sm:w-[500px] h-screen md:h-[650px] p-[30px] sm:rounded shadow-sm items-center relative"> */}
      <Routes>
        <Route path="/" element={<SignupPage />} />
        <Route path="/lead-not-found" element={<LeadNotFoundPage />} />
        <Route path="/redirect" element={<RedirectToAppStoreAndPlayStore />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {/* </div> */}
      {/* </div> */}
    </>
  );
}

export default App;
