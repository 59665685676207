import React, { useEffect } from 'react';
import { FaApple, FaGooglePlay } from 'react-icons/fa';
import { useUserAuth } from '../providers/UserAuthContext';
import { isIOS, isAndroid } from 'react-device-detect';
import Logo from '../assets/images/daylight-logo-long.png';
import DayLightLogo from '../resources/images/DayLightLogo';

const RedirectToAppStoreAndPlayStore = () => {
  const { redirectToAppStore, redirectToPlayStore } = useUserAuth();

  useEffect(() => {
    if (isIOS) redirectToAppStore();
    if (isAndroid) redirectToPlayStore();
  }, []);

  return (
    <div className=" flex item-center justify-center h-screen bg-neutral text-center">
      <div className="m-auto bg-white w-full sm:w-[500px] h-screen md:h-[650px] p-[30px] sm:rounded shadow-sm items-center relative">
        <div className="w-full flex flex-col gap-2 rounded-lg p-3">
          <div className="text-center">
            {/* <img className="inline mb-6 w-[75%]" src={Logo} alt="img" /> */}
            <div className="sm:max-w-[181px] max-w-[105px] lg:max-h-[52px] max-h-[30px] my-[12px] mx-auto">
              <DayLightLogo />
            </div>
            <h6 className="aeonik-regular text-2xl text-black my-4 mb-8 text-center">
              Redirect to App Store and Play Store
            </h6>
            <div className="mt-6 space-y-4 flex flex-col justify-center items-center">
              <button
                className="inline-flex items-center px-6 py-3 rounded-lg bg-white text-tertiary cursor-pointer sm:w-full md:w-[90%] border-tertiary border-2 hover:bg-neutral"
                onClick={redirectToPlayStore}>
                <FaGooglePlay className="text-4xl" />
                <span className="flex flex-col items-start ml-4 leading-none">
                  <span className="mb-1 text-xs">GET IT ON</span>
                  <span className="font-semibold title-font text-xl">Google Play</span>
                </span>
              </button>
              <button
                className="inline-flex items-center px-6 py-3 rounded-lg bg-white text-tertiary cursor-pointer sm:w-full md:w-[90%] border-tertiary border-2 hover:bg-neutral"
                onClick={redirectToAppStore}>
                <FaApple className="text-5xl" />
                <span className="flex flex-col items-start ml-4 leading-none">
                  <span className="mb-1 text-xs">Download on the</span>
                  <span className="font-semibold title-font text-xl">App Store</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedirectToAppStoreAndPlayStore;
