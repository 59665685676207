import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, from } from '@apollo/client';
// import { onError } from "@apollo/client/link/error"
import { BrowserRouter } from 'react-router-dom';
import { UserAuthContextProvider } from './providers/UserAuthContext';

// const errorLink = onError(({ graphqlErrors, networkError }) => {
//   if (graphqlErrors) {
//     graphqlErrors.map(({ message, location, path }) => {
//       // alert(`Graphql error ${message}`)
//       return {"message":`Graphqllllll ${message}`}
//       // console.log(`Graphql error ${message}`);
//     })
//   }
// })

// const link = from([errorLink, new HttpLink({ uri: process.env.REACT_APP_GRAPH_QL_BASE_URL })]);

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPH_QL_BASE_URL,
  cache: new InMemoryCache(),
  // link: link
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <UserAuthContextProvider>
          <App />
        </UserAuthContextProvider>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
