import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SignUpMainScreen from '../components/SignUpMainScreen';
import './SignupPage.css';
import PhoneNumberVerification from '../components/PhoneNumberVerification';
import MainScreen from '../components/MainScreen/MainScreen';

const SignupPage = () => {
  return (
    <div>
      {/* <SignUpMainScreen /> */}
      {/* <PhoneNumberVerification /> */}
      <MainScreen />
    </div>
  );
};

export default SignupPage;
